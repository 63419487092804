import * as bowser from "bowser";
import { List } from "immutable";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";

export interface IBrowserDownload {
    name: string;
    iconPreset: IconPreset;
    href: string;
}

export function getBrowserDownloadLinks(): List<IBrowserDownload> {
    let list = List<IBrowserDownload>();

    if (!isValidBrowser()) {
        list = list.push({ name: "Chrome", iconPreset: "chrome" as IconPreset, href: "https://www.google.com/chrome/browser/desktop/index.html" });
        if (bowser.mac) {
            list = list.push({ name: "Safari", iconPreset: "safari" as IconPreset, href: "https://www.apple.com/safari/" });
        }
    }

    return list;
}

function isValidBrowser() {
    return bowser.check({ chrome: "60", safari: "10", msie: "11" }, true);
    //return bowser.chrome || bowser.safari || (bowser.msie && bowser.version === "11.0");
}

export function isApp() {
    const storageManager = StorageManager.GetInstance();
    return storageManager.retrieve("isApp", EStorageType.LocalStorage) === "true";
}

export function isLegacyApp() {
    return typeof window.invokeCSharpAction !== "function";
}

export function sendAppMessage(message: string) {
    if (typeof window.invokeCSharpAction === "function") {
        window.invokeCSharpAction(message);
        //@ts-ignore
    } else if (window.ReactNativeWebView) {
        //@ts-ignore
        window.ReactNativeWebView.postMessage(message);
    }
}

export function sendAppRouteNavigation(route: string) {
    if (typeof window.routeNavigation !== "function") {
        return;
    }

    window.routeNavigation(route);
}

export { isSafariOnMac, isInternetExplorer, isAndroid, isIOs } from "common/modules/browserCheckHelper";
