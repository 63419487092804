import * as SystemMetaInformation from "framework/models/systemMetaInformation";

import Insights from "framework/modules/insights";
import userStore from "framework/stores/userStore";

import { List } from "immutable";
import { cookie } from "browser-cookie-lite";
import { createReducer } from "@reduxjs/toolkit";
import { dispatch } from "framework/actions/action";
import { history } from "framework/modules/historyHelper";
import { ReduceStore } from "framework/stores/reduceStore";
import { LoadingStatus } from "framework/models/loadingStatusEnum";
import { SetShowDebugInfo } from "framework/actions/applicationActions";
import { ISystemMetaInformation } from "framework/models/systemMetaInformation";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { IdentitySystemMetaInformation } from "framework/models/identitySystemMetaInformation";
import {
    addFavouriteSuccess,
    removeFavouriteSuccess,
    loadingFavourites,
    loadFavouritesSuccess,
    loadRecentlyUsedSuccess,
    loadingRecentlyUsed,
    addRecentlyUsedSuccess,
    removeRecentlyUsedSuccess,
    removeAllRecentlyUsedSuccess,
    loadingSystems,
    loadSystemsSuccess,
    loadingSystemPreferences,
    loadSystemPreferencesSuccess,
    loadSystemSuccess,
    searchSystemsSuccess,
    loadingSearchSystems,
    updateSystemNameSucess,
    setSystem,
    removeSystem,
    resetToFavouritesRecently,
    addSystemToKeepForFields,
    setSystemSSO,
} from "application/actions/systemActions";

export type SystemSelectorStoreState = {
    systems: List<ISystemMetaInformation>;
    systemsForFields: List<ISystemMetaInformation>;
    defaultSystems: List<ISystemMetaInformation>;
    systemPreferences: List<IdentitySystemMetaInformation>;
    selectedSystem?: ISystemMetaInformation;
    systemSearchStatus: LoadingStatus;
    systemPreferencesStatus: LoadingStatus;
    systemsStatus: LoadingStatus;
    favouriteSystemsStatus: LoadingStatus;
    recentlyUsedSystemsStatus: LoadingStatus;
};

const initialState: SystemSelectorStoreState = {
    systems: List<ISystemMetaInformation>(),
    systemsForFields: List<ISystemMetaInformation>(),
    defaultSystems: List<ISystemMetaInformation>(),
    systemPreferences: List<IdentitySystemMetaInformation>(),
    selectedSystem: undefined,
    systemSearchStatus: LoadingStatus.Loaded,
    systemPreferencesStatus: LoadingStatus.NotLoaded,
    systemsStatus: LoadingStatus.NotLoaded,
    favouriteSystemsStatus: LoadingStatus.NotLoaded,
    recentlyUsedSystemsStatus: LoadingStatus.NotLoaded,
};

const systemSelectorReducer = createReducer(initialState, (builder) => {
    const storageManager: StorageManager = StorageManager.GetInstance();

    const handleSystemsChanged = (selectedSystem: ISystemMetaInformation | undefined, systems: List<ISystemMetaInformation>) => {
        const initsystem = storageManager.retrieve("user.settings.initSystem", EStorageType.LocalStorage, true);
        let redirect = false;

        if (initsystem) {
            storageManager.remove("user.settings.initSystem", EStorageType.LocalStorage, true);
            selectedSystem = SystemMetaInformation.Record(JSON.parse(initsystem));
            redirect = true;
        }

        if (selectedSystem && systems && systems.some((s) => s.name === selectedSystem!.name)) {
            dispatch(
                setSystem({
                    system: selectedSystem.name,
                    redirect: redirect,
                    autoSelected: true,
                })
            );
        }

        if (selectedSystem && systems?.count() > 0 && !systems.find((s) => s.name === selectedSystem!.name)) {
            selectedSystem = undefined;
        }

        if (!selectedSystem && systems && systems.count() === 1 && !userStore.getIsJestOrCheckout()) {
            dispatch(
                setSystem({
                    system: systems.first()!.name,
                    redirect: true,
                    autoSelected: false,
                })
            );
        }
    };

    const popInitialRedirect = () => {
        let initialRedirect = storageManager.retrieve("user.settings.initialRedirect", EStorageType.LocalStorage, true);
        if (initialRedirect) {
            storageManager.remove("user.settings.initialRedirect", EStorageType.LocalStorage, true);
            if (initialRedirect[0] !== "/") {
                initialRedirect = "/" + initialRedirect;
            }
        }
        return initialRedirect;
    };

    const updateDocumentTitle = (sys: ISystemMetaInformation) => {
        if (document.location.hostname === "localhost") {
            document.title = `localhost | ${sys.commonName}`;
        } else if (document.location.origin.includes(".azure.caspeco.com")) {
            document.title = `custom | ${sys.commonName}`;
        } else {
            document.title = sys.commonName;
        }
    };

    const addSystemToSystemsForFields = (system: ISystemMetaInformation, systemsForFields: List<ISystemMetaInformation>): List<ISystemMetaInformation> => {
        if (!systemsForFields.some((s) => s.name === system.name)) {
            return systemsForFields.push(system);
        }

        return systemsForFields;
    };

    builder.addCase(removeSystem, (state, action) => {
        state.systems = state.systems.filterNot((sys) => sys.name === action.payload.name).toList();
    });

    builder.addCase(setSystemSSO, (_state, _action) => {
        const selectedSystemsString = storageManager.retrieve("lastUsedSystem", EStorageType.SessionStorage, true);
        if (!selectedSystemsString) {
            return;
        }

        dispatch(
            setSystem({
                system: selectedSystemsString,
                redirect: false,
                autoSelected: true,
            })
        );
    });

    builder.addCase(setSystem, (state, action) => {
        let sys;
        if (state.systems.count() === 0) {
            sys = SystemMetaInformation.Record({
                name: action.payload.system,
                commonName: action.payload.system,
            });
        } else {
            sys = state.systems.find((system) => system.name === action.payload.system);
        }

        let requestRestart = false;
        state.selectedSystem = sys;
        if (
            !sys.isLive &&
            sys.deploymentMarc &&
            sys.deploymentMarc !== "trackingdev-as-ne" &&
            localStorage.getItem("api.settings.url") &&
            localStorage.getItem("app.settings.debugBar") !== "0"
        ) {
            setTimeout(() => new SetShowDebugInfo(true), 0);
        }
        if (sys === null || sys === undefined) {
            // clear current systems and perhaps restart
            storageManager.remove("user.settings.initSystem", EStorageType.LocalStorage, true);
            if (requestRestart) {
                window.location.assign(userStore.getOrigin());
            }
            return;
        }
        if (action.payload.autoSelected) {
            requestRestart = false;
        } else {
            requestRestart = true;
        }
        const subjectId = userStore.getUserId();
        storageManager.store("currentUserId", subjectId || "", EStorageType.LocalStorage, true);
        storageManager.store("lastUsedSystem", sys.name, EStorageType.SessionStorage);
        cookie("system", undefined, -1); //Clear cookie for this path to get rid of old incorrectly set values
        cookie("system", sys.name, 0, "/");
        if (requestRestart) {
            const currentUIDeployment = userStore.getUIDeployment();
            cookie("RP-Deployment", undefined, -1); //Clear cookie for this path to get rid of old incorrectly set values
            sys = <ISystemMetaInformation>sys.set("deploymentWebPlatform", currentUIDeployment);
            storageManager.store("user.settings.initSystem", JSON.stringify(sys), EStorageType.LocalStorage, true);
            let initialRedirect = popInitialRedirect();
            if (!initialRedirect) {
                if (userStore.getIsJestOrCheckout()) {
                    // Reload but no redirect for checkout
                    initialRedirect = document.location.pathname;
                } else {
                    initialRedirect = "";
                }
            }
            window.location.assign(userStore.getOrigin() + initialRedirect);
        }
        // it seems like this is the earliest place we have call getUserId
        if (subjectId) Insights.setUser(subjectId);
        Insights.setSystem(sys.name);
        if (subjectId !== null) storageManager.setPrefix(sys.name, subjectId);
        Insights.setCustomAttribute("user.system", sys.name);
        Insights.trackEvent("system.select");
        // update document title
        updateDocumentTitle(sys);
        if (action.payload.redirect) {
            let initialRedirect = popInitialRedirect();
            history.push(initialRedirect ?? "/");
        }
    });

    builder.addCase(loadSystemSuccess, (state, action) => {
        state.systems = state.systems
            .filterNot((system) => system.name === action.payload.system.name)
            .toList()
            .push(action.payload.system)
            .sortBy((system) => system.name)
            .toList();

        handleSystemsChanged(state.selectedSystem, state.systems);
        if (action.payload.keepForFieldSelector) {
            state.systemsForFields = addSystemToSystemsForFields(action.payload.system, state.systemsForFields);
        }
    });

    builder.addCase(addSystemToKeepForFields, (state, action) => {
        state.systemsForFields = addSystemToSystemsForFields(action.payload, state.systemsForFields);
    });

    builder.addCase(loadingSystems, (state) => {
        state.systemsStatus = LoadingStatus.Loading;
    });

    builder.addCase(loadingSearchSystems, (state) => {
        state.systemSearchStatus = LoadingStatus.Loading;
    });

    builder.addCase(loadingFavourites, (state) => {
        state.favouriteSystemsStatus = LoadingStatus.Loading;
    });

    builder.addCase(loadingRecentlyUsed, (state) => {
        state.recentlyUsedSystemsStatus = LoadingStatus.Loading;
    });

    builder.addCase(loadingSystemPreferences, (state) => {
        state.systemPreferencesStatus = LoadingStatus.Loading;
    });

    builder.addCase(loadSystemsSuccess, (state, action) => {
        // This will never add any systems for Caspeco.Support users (the action will always have an empty list)
        state.systems = state.systems
            .filterNot((sys) => action.payload.some((loadedSys) => sys.name === loadedSys.name))
            .concat(action.payload)
            .sortBy((system) => system.name)
            .toList();
        state.systemsStatus = LoadingStatus.Loaded;

        handleSystemsChanged(state.selectedSystem, state.systems);
    });

    builder.addCase(resetToFavouritesRecently, (state) => {
        state.systems = state.defaultSystems;
    });

    builder.addCase(searchSystemsSuccess, (state, action) => {
        let favSystems = state.systems.filter((sys) => sys.isFavourite === true).toList();
        let recentSystems = state.systems.filter((sys) => sys.isRecentlyUsed === true).toList();
        let searchedSystems = action.payload.systems;

        if (action.payload.purgeOtherSystems) {
            state.systems = List<ISystemMetaInformation>();
        }

        state.systems = state.systems
            .filterNot((sys) => searchedSystems.some((searchedSys) => sys.name === searchedSys.name))
            .concat(searchedSystems)
            .sortBy((system) => system.name)
            .toList();

        state.systems
            .filter((sys) => favSystems.some((favSys) => sys.name === favSys.name))
            .forEach((sys) => {
                sys.isFavourite = true;
            });

        state.systems
            .filter((sys) => recentSystems.some((recSys) => sys.name === recSys.name))
            .forEach((sys) => {
                sys.isRecentlyUsed = true;
            });

        // This is to prevent the system dropdown from being empty
        if (state.systems.count() === 0) {
            state.systems = state.systems.push(state.selectedSystem!);
        }

        state.systemSearchStatus = LoadingStatus.Loaded;
    });

    builder.addCase(loadFavouritesSuccess, (state, action) => {
        action.payload.forEach((system) => {
            let isRecent = state.systems.find((sys) => sys.name === system.name)?.isRecentlyUsed ?? false;
            system.isFavourite = true;
            system.isRecentlyUsed = isRecent;
        });

        state.systems = state.systems
            .filterNot((sys) => action.payload.some((loadedSys) => sys.name === loadedSys.name))
            .concat(action.payload)
            .sortBy((system) => system.name)
            .toList();

        state.defaultSystems = state.systems;
        state.favouriteSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(loadSystemPreferencesSuccess, (state, action) => {
        state.systemPreferences = action.payload;
        state.systemPreferencesStatus = LoadingStatus.Loaded;
    });

    builder.addCase(loadRecentlyUsedSuccess, (state, action) => {
        action.payload.forEach((system) => {
            let isFav = state.systems.find((sys) => sys.name === system.name)?.isFavourite ?? false;
            system.isRecentlyUsed = true;
            system.isFavourite = isFav;
        });

        state.systems = state.systems
            .filterNot((sys) => action.payload.some((loadedSys) => sys.name === loadedSys.name))
            .concat(action.payload)
            .sortBy((system) => system.name)
            .toList();

        state.defaultSystems = state.systems;
        state.recentlyUsedSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(addFavouriteSuccess, (state, action) => {
        action.payload.isFavourite = true;
        state.systems = state.systems
            .filterNot((system) => system.name === action.payload.name)
            .toList()
            .push(action.payload)
            .sortBy((system) => system.name)
            .toList();
        state.favouriteSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(removeFavouriteSuccess, (state, action) => {
        action.payload.isFavourite = false;
        state.systems = state.systems
            .filterNot((system) => system.name === action.payload.name)
            .toList()
            .push(action.payload)
            .sortBy((system) => system.name)
            .toList();
        state.favouriteSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(addRecentlyUsedSuccess, (state, action) => {
        action.payload.isRecentlyUsed = true;
        state.systems = state.systems
            .filterNot((system) => system.name === action.payload.name)
            .toList()
            .push(action.payload)
            .sortBy((system) => system.name)
            .toList();
        state.recentlyUsedSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(removeRecentlyUsedSuccess, (state, action) => {
        action.payload.isRecentlyUsed = false;
        state.systems = state.systems
            .filterNot((system) => system.name === action.payload.name)
            .toList()
            .push(action.payload)
            .sortBy((system) => system.name)
            .toList();
        state.recentlyUsedSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(removeAllRecentlyUsedSuccess, (state) => {
        state.systems.forEach((sys) => {
            sys.isRecentlyUsed = false;
        });
        state.recentlyUsedSystemsStatus = LoadingStatus.Loaded;
    });

    builder.addCase(updateSystemNameSucess, (state, action) => {
        let system = state.selectedSystem!;
        let availableSystems = state.systems;

        if (system.name === action.payload.system && system.commonName !== action.payload.businessName) {
            system = <ISystemMetaInformation>system.set("commonName", action.payload.businessName);
            if (system && availableSystems) {
                const index = availableSystems.findIndex((s) => s.name === system.name);
                let systemToUpdate = availableSystems.get(index);
                systemToUpdate = <ISystemMetaInformation>systemToUpdate.set("commonName", action.payload.businessName);
                availableSystems = availableSystems.set(index, systemToUpdate);
                state.systems = availableSystems;
            }

            dispatch(
                setSystem({
                    system: system.name,
                    redirect: false,
                    autoSelected: false,
                })
            );
        }
    });
});

export const SystemStore = new ReduceStore(systemSelectorReducer);
