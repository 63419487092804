import { IconPack } from "@fortawesome/fontawesome-common-types";

// Use yarn gen fa-icon to add new icon to the library
/* PLOP_INJECT_IMPORT */
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faFileCsv } from "@fortawesome/pro-regular-svg-icons/faFileCsv";
import { faSquareCheck } from "@fortawesome/pro-regular-svg-icons/faSquareCheck";
import { faSquareXmark } from "@fortawesome/pro-regular-svg-icons/faSquareXmark";
import { faCoins } from "@fortawesome/pro-regular-svg-icons/faCoins";
import { faCircleStop } from "@fortawesome/pro-regular-svg-icons/faCircleStop";
import { faWandMagicSparkles } from "@fortawesome/pro-regular-svg-icons/faWandMagicSparkles";
import { faChartArea } from "@fortawesome/pro-regular-svg-icons/faChartArea";
import { faChartSimple } from "@fortawesome/pro-regular-svg-icons/faChartSimple";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare";
import { faLockOpen } from "@fortawesome/pro-regular-svg-icons/faLockOpen";
import { faLockKeyhole } from "@fortawesome/pro-regular-svg-icons/faLockKeyhole";
import { faLockKeyholeOpen } from "@fortawesome/pro-regular-svg-icons/faLockKeyholeOpen";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faChartColumn } from "@fortawesome/pro-regular-svg-icons/faChartColumn";
import { faSquareMinus } from "@fortawesome/pro-regular-svg-icons/faSquareMinus";
import { faChartLineUp } from "@fortawesome/pro-regular-svg-icons/faChartLineUp";
import { faTablePivot } from "@fortawesome/pro-regular-svg-icons/faTablePivot";
import { faMugSaucer } from "@fortawesome/pro-regular-svg-icons/faMugSaucer";
import { faCaretDown } from "@fortawesome/pro-regular-svg-icons/faCaretDown";
import { faChartLineDown } from "@fortawesome/pro-regular-svg-icons/faChartLineDown";
import { faSteeringWheel } from "@fortawesome/pro-regular-svg-icons/faSteeringWheel";
import { faCompressArrowsAlt } from "@fortawesome/pro-regular-svg-icons/faCompressArrowsAlt";
import { faExpandAlt } from "@fortawesome/pro-regular-svg-icons/faExpandAlt";
import { faCompressAlt } from "@fortawesome/pro-regular-svg-icons/faCompressAlt";
import { faTurtle } from "@fortawesome/pro-regular-svg-icons/faTurtle";
import { faPennant } from "@fortawesome/pro-regular-svg-icons/faPennant";
import { faTools } from "@fortawesome/pro-regular-svg-icons/faTools";
import { faThumbtack } from "@fortawesome/pro-regular-svg-icons/faThumbtack";
import { faPauseCircle } from "@fortawesome/pro-regular-svg-icons/faPauseCircle";
import { faAddressCard } from "@fortawesome/pro-regular-svg-icons/faAddressCard";
import { faFastForward } from "@fortawesome/pro-regular-svg-icons/faFastForward";
import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons/faArrowToBottom";
import { faArrowToRight } from "@fortawesome/pro-regular-svg-icons/faArrowToRight";
import { faArrowToTop } from "@fortawesome/pro-regular-svg-icons/faArrowToTop";
import { faArrowToLeft } from "@fortawesome/pro-regular-svg-icons/faArrowToLeft";
import { faArrowFromRight } from "@fortawesome/pro-regular-svg-icons/faArrowFromRight";
import { faArrowFromTop } from "@fortawesome/pro-regular-svg-icons/faArrowFromTop";
import { faArrowFromBottom } from "@fortawesome/pro-regular-svg-icons/faArrowFromBottom";
import { faArrowFromLeft } from "@fortawesome/pro-regular-svg-icons/faArrowFromLeft";
import { faServer } from "@fortawesome/pro-regular-svg-icons/faServer";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons/faLayerGroup";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faCalendarPlus } from "@fortawesome/pro-regular-svg-icons/faCalendarPlus";
import { faHatChef } from "@fortawesome/pro-regular-svg-icons/faHatChef";
import { faBirthdayCake } from "@fortawesome/pro-regular-svg-icons/faBirthdayCake";
import { faCommentExclamation } from "@fortawesome/pro-regular-svg-icons/faCommentExclamation";
import { faCommentEdit } from "@fortawesome/pro-regular-svg-icons/faCommentEdit";
import { faInfoSquare } from "@fortawesome/pro-regular-svg-icons/faInfoSquare";
import { faVideo } from "@fortawesome/pro-regular-svg-icons/faVideo";
import { faSortDown } from "@fortawesome/pro-regular-svg-icons/faSortDown";
import { faSortUp } from "@fortawesome/pro-regular-svg-icons/faSortUp";
import { faFlagCheckered } from "@fortawesome/pro-regular-svg-icons/faFlagCheckered";
import { faTableTennis } from "@fortawesome/pro-regular-svg-icons/faTableTennis";
import { faMicrophoneStand } from "@fortawesome/pro-regular-svg-icons/faMicrophoneStand";
import { faGolfClub } from "@fortawesome/pro-regular-svg-icons/faGolfClub";
import { faGolfBall } from "@fortawesome/pro-regular-svg-icons/faGolfBall";
import { faBasketballBall } from "@fortawesome/pro-regular-svg-icons/faBasketballBall";
import { faMapPin } from "@fortawesome/pro-regular-svg-icons/faMapPin";
import { faBullseyeArrow } from "@fortawesome/pro-regular-svg-icons/faBullseyeArrow";
import { faCompactDisc } from "@fortawesome/pro-regular-svg-icons/faCompactDisc";
import { faLightbulb } from "@fortawesome/pro-regular-svg-icons/faLightbulb";
import { faPlay } from "@fortawesome/pro-regular-svg-icons/faPlay";
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle";
import { faBowlingBall } from "@fortawesome/pro-regular-svg-icons/faBowlingBall";
import { faTintSlash } from "@fortawesome/pro-regular-svg-icons/faTintSlash";
import { faSortAlt } from "@fortawesome/pro-regular-svg-icons/faSortAlt";
import { faCalendarExclamation } from "@fortawesome/pro-regular-svg-icons/faCalendarExclamation";
import { faText } from "@fortawesome/pro-regular-svg-icons/faText";
import { faChevronDoubleDown } from "@fortawesome/pro-regular-svg-icons/faChevronDoubleDown";
import { faInboxOut } from "@fortawesome/pro-regular-svg-icons/faInboxOut";
import { faArchive } from "@fortawesome/pro-regular-svg-icons/faArchive";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faSun } from "@fortawesome/pro-regular-svg-icons/faSun";
import { faLineColumns } from "@fortawesome/pro-regular-svg-icons/faLineColumns";
import { faCommentSmile } from "@fortawesome/pro-regular-svg-icons/faCommentSmile";
import { faCompress } from "@fortawesome/pro-regular-svg-icons/faCompress";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { faH3 } from "@fortawesome/pro-regular-svg-icons/faH3";
import { faH2 } from "@fortawesome/pro-regular-svg-icons/faH2";
import { faH1 } from "@fortawesome/pro-regular-svg-icons/faH1";
import { faQuoteRight } from "@fortawesome/pro-regular-svg-icons/faQuoteRight";
import { faCode } from "@fortawesome/pro-regular-svg-icons/faCode";
import { faListOl } from "@fortawesome/pro-regular-svg-icons/faListOl";
import { faListUl } from "@fortawesome/pro-regular-svg-icons/faListUl";
import { faUnderline } from "@fortawesome/pro-regular-svg-icons/faUnderline";
import { faBold } from "@fortawesome/pro-regular-svg-icons/faBold";
import { faItalic } from "@fortawesome/pro-regular-svg-icons/faItalic";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons/faLightbulbOn";
import { faConciergeBell } from "@fortawesome/pro-regular-svg-icons/faConciergeBell";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons/faMapMarkerAlt";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faArrowCircleRight } from "@fortawesome/pro-regular-svg-icons/faArrowCircleRight";
import { faArrowCircleUp } from "@fortawesome/pro-regular-svg-icons/faArrowCircleUp";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { faUndo } from "@fortawesome/pro-regular-svg-icons/faUndo";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { faSignIn } from "@fortawesome/pro-regular-svg-icons/faSignIn";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import { faBook } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons/faShoppingCart";
import { faUniversity } from "@fortawesome/pro-regular-svg-icons/faUniversity";
import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faWrench } from "@fortawesome/pro-regular-svg-icons/faWrench";
import { faCubes } from "@fortawesome/pro-regular-svg-icons/faCubes";
import { faCaretRight } from "@fortawesome/pro-regular-svg-icons/faCaretRight";
import { faMoneyBill } from "@fortawesome/pro-regular-svg-icons/faMoneyBill";
import { faLifeRing } from "@fortawesome/pro-regular-svg-icons/faLifeRing";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons/faSyncAlt";
import { faCaretLeft } from "@fortawesome/pro-regular-svg-icons/faCaretLeft";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faCube } from "@fortawesome/pro-regular-svg-icons/faCube";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faUnlink } from "@fortawesome/pro-regular-svg-icons/faUnlink";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faChild } from "@fortawesome/pro-regular-svg-icons/faChild";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faUserTimes } from "@fortawesome/pro-regular-svg-icons/faUserTimes";
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons/faCheckSquare";
import { faSave } from "@fortawesome/pro-regular-svg-icons/faSave";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faPrint } from "@fortawesome/pro-regular-svg-icons/faPrint";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons/faFileExcel";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faSearchPlus } from "@fortawesome/pro-regular-svg-icons/faSearchPlus";
import { faSearchMinus } from "@fortawesome/pro-regular-svg-icons/faSearchMinus";
import { faArrowsAlt } from "@fortawesome/pro-regular-svg-icons/faArrowsAlt";
import { faWindowMinimize } from "@fortawesome/pro-regular-svg-icons/faWindowMinimize";
import { faCut } from "@fortawesome/pro-regular-svg-icons/faCut";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faPaste } from "@fortawesome/pro-regular-svg-icons/faPaste";
import { faColumns } from "@fortawesome/pro-regular-svg-icons/faColumns";
import { faCaretSquareRight } from "@fortawesome/pro-regular-svg-icons/faCaretSquareRight";
import { faCaretSquareLeft } from "@fortawesome/pro-regular-svg-icons/faCaretSquareLeft";
import { faFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
import { faMousePointer } from "@fortawesome/pro-regular-svg-icons/faMousePointer";
import { faExpand } from "@fortawesome/pro-regular-svg-icons/faExpand";
import { faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleRight";
import { faAngleDoubleUp } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleUp";
import { faAngleDoubleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleDown";
import { faRepeat } from "@fortawesome/pro-regular-svg-icons/faRepeat";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faInfo } from "@fortawesome/pro-regular-svg-icons/faInfo";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faComment } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faCommentDots } from "@fortawesome/pro-regular-svg-icons/faCommentDots";
import { faCalendarTimes } from "@fortawesome/pro-regular-svg-icons/faCalendarTimes";
import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons/faCalendarCheck";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faExclamation } from "@fortawesome/pro-regular-svg-icons/faExclamation";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faPhone } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { faMobile } from "@fortawesome/pro-regular-svg-icons/faMobile";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons/faNewspaper";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faBriefcase } from "@fortawesome/pro-regular-svg-icons/faBriefcase";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar";

import { faCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faDotCircle } from "@fortawesome/pro-regular-svg-icons/faDotCircle";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons/faFileAlt";
import { faFolder } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faFolderOpen } from "@fortawesome/pro-regular-svg-icons/faFolderOpen";
import { faMoneyBillAlt } from "@fortawesome/pro-regular-svg-icons/faMoneyBillAlt";
import { faMoneyBillWave } from "@fortawesome/pro-regular-svg-icons/faMoneyBillWave";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons/faCreditCard";
import { faPuzzlePiece } from "@fortawesome/pro-regular-svg-icons/faPuzzlePiece";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faMapMarker } from "@fortawesome/pro-regular-svg-icons/faMapMarker";
import { faStickyNote } from "@fortawesome/pro-regular-svg-icons/faStickyNote";
import { faBullhorn } from "@fortawesome/pro-regular-svg-icons/faBullhorn";
import { faFlask } from "@fortawesome/pro-regular-svg-icons/faFlask";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faRocket } from "@fortawesome/pro-regular-svg-icons/faRocket";
import { faArrows } from "@fortawesome/pro-regular-svg-icons/faArrows";
import { faArrowsH } from "@fortawesome/pro-regular-svg-icons/faArrowsH";
import { faArrowsV } from "@fortawesome/pro-regular-svg-icons/faArrowsV";
import { faTrophy } from "@fortawesome/pro-regular-svg-icons/faTrophy";
import { faTrophyAlt } from "@fortawesome/pro-regular-svg-icons/faTrophyAlt";
import { faThumbsUp } from "@fortawesome/pro-regular-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/pro-regular-svg-icons/faThumbsDown";
import { faThermometerFull } from "@fortawesome/pro-regular-svg-icons/faThermometerFull";
import { faThermometerHalf } from "@fortawesome/pro-regular-svg-icons/faThermometerHalf";
import { faThermometerEmpty } from "@fortawesome/pro-regular-svg-icons/faThermometerEmpty";
import { faChartBar } from "@fortawesome/pro-regular-svg-icons/faChartBar";
import { faChartPie } from "@fortawesome/pro-regular-svg-icons/faChartPie";
import { faChartLine } from "@fortawesome/pro-regular-svg-icons/faChartLine";
import { faTable } from "@fortawesome/pro-regular-svg-icons/faTable";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faSitemap } from "@fortawesome/pro-regular-svg-icons/faSitemap";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faBeer } from "@fortawesome/pro-regular-svg-icons/faBeer";
import { faTint } from "@fortawesome/pro-regular-svg-icons/faTint";
import { faAsterisk } from "@fortawesome/pro-regular-svg-icons/faAsterisk";
import { faCar } from "@fortawesome/pro-regular-svg-icons/faCar";
import { faGem } from "@fortawesome/pro-regular-svg-icons/faGem";
import { faBinoculars } from "@fortawesome/pro-regular-svg-icons/faBinoculars";
import { faAnchor } from "@fortawesome/pro-regular-svg-icons/faAnchor";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons/faUtensils";
import { faMusic } from "@fortawesome/pro-regular-svg-icons/faMusic";
import { faMoon } from "@fortawesome/pro-regular-svg-icons/faMoon";
import { faStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faStarHalfAlt } from "@fortawesome/pro-regular-svg-icons/faStarHalfAlt";
import { faBicycle } from "@fortawesome/pro-regular-svg-icons/faBicycle";
import { faWheelchair } from "@fortawesome/pro-regular-svg-icons/faWheelchair";
import { faTree } from "@fortawesome/pro-regular-svg-icons/faTree";
import { faMagnet } from "@fortawesome/pro-regular-svg-icons/faMagnet";
import { faHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";
import { faBomb } from "@fortawesome/pro-regular-svg-icons/faBomb";
import { faCocktail } from "@fortawesome/pro-regular-svg-icons/faCocktail";
import { faBullseye } from "@fortawesome/pro-regular-svg-icons/faBullseye";
import { faBowlingPins } from "@fortawesome/pro-regular-svg-icons/faBowlingPins";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons/faPaperPlane";
import { faTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons/faExclamationCircle";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faExchange } from "@fortawesome/pro-regular-svg-icons/faExchange";
import { faCashRegister } from "@fortawesome/pro-regular-svg-icons/faCashRegister";
import { faCloud } from "@fortawesome/pro-regular-svg-icons/faCloud";
import { faBug } from "@fortawesome/pro-regular-svg-icons/faBug";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { faComments } from "@fortawesome/pro-regular-svg-icons/faComments";
import { faLanguage } from "@fortawesome/pro-regular-svg-icons/faLanguage";
import { faCoffee } from "@fortawesome/pro-regular-svg-icons/faCoffee";

export default <IconPack>{
    /* PLOP_INJECT_EXPORT */
    faXmark,
    faArrowsRotate,
    faFileCsv,
    faSquareCheck,
    faSquareXmark,
    faCoins,
    faCircleStop,
    faWandMagicSparkles,
    faChartArea,
    faChartSimple,
    faArrowUpRightFromSquare,
    faLockOpen,
    faLockKeyhole,
    faLockKeyholeOpen,
    faLock,
    faChartColumn,
    faSquareMinus,
    faChartLineUp,
    faTablePivot,
    faMugSaucer,
    faCaretDown,
    faChartLineDown,
    faSteeringWheel,
    faCompressArrowsAlt,
    faExpandAlt,
    faCompressAlt,
    faTurtle,
    faPennant,
    faTools,
    faThumbtack,
    faPauseCircle,
    faAddressCard,
    faFastForward,
    faBan,
    faArrowToBottom,
    faArrowToRight,
    faArrowToTop,
    faArrowToLeft,
    faArrowFromRight,
    faArrowFromTop,
    faArrowFromBottom,
    faArrowFromLeft,
    faServer,
    faLayerGroup,
    faPlusCircle,
    faCalendarPlus,
    faHatChef,
    faBirthdayCake,
    faCommentExclamation,
    faCommentEdit,
    faInfoSquare,
    faVideo,
    faSortDown,
    faSortUp,
    faFlagCheckered,
    faTableTennis,
    faMicrophoneStand,
    faGolfClub,
    faGolfBall,
    faBasketballBall,
    faMapPin,
    faBullseyeArrow,
    faCompactDisc,
    faLightbulb,
    faPlay,
    faPlayCircle,
    faBowlingBall,
    faTintSlash,
    faSortAlt,
    faCalendarExclamation,
    faText,
    faChevronDoubleDown,
    faInboxOut,
    faArchive,
    faEyeSlash,
    faSun,
    faLineColumns,
    faCommentSmile,
    faCompress,
    faReceipt,
    faImage,
    faH3,
    faH2,
    faH1,
    faQuoteRight,
    faCode,
    faListOl,
    faListUl,
    faUnderline,
    faBold,
    faItalic,
    faLightbulbOn,
    faConciergeBell,
    faMapMarkerAlt,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowCircleUp,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faBars,
    faEllipsisV,
    faEllipsisH,
    faFile,
    faMapMarker,
    faClock,
    faCoffee,
    faUserTimes,
    faHistory,
    faUndo,
    faSignOut,
    faSignIn,
    faUser,
    faExclamation,
    faUsers,
    faFileAlt,
    faCalendarAlt,
    faBook,
    faChartBar,
    faChartLine,
    faShoppingCart,
    faUniversity,
    faCog,
    faWrench,
    faCubes,
    faCaretRight,
    faMoneyBill,
    faLifeRing,
    faSyncAlt,
    faCaretLeft,
    faDotCircle,
    faPlus,
    faMinus,
    faCube,
    faTrash,
    faUnlink,
    faList,
    faChild,
    faUserPlus,
    faCheckSquare,
    faTimesCircle,
    faSave,
    faEdit,
    faPrint,
    faEnvelope,
    faFileExcel,
    faFilePdf,
    faFileExport,
    faTimes,
    faSearchPlus,
    faSearchMinus,
    faArrowsAlt,
    faWindowMinimize,
    faCut,
    faCopy,
    faPaste,
    faFolder,
    faFolderOpen,
    faColumns,
    faCaretSquareRight,
    faCaretSquareLeft,
    faFilter,
    faMousePointer,
    faExpand,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faRepeat,
    faCheckCircle,
    faCircle,
    faInfo,
    faExclamationTriangle,
    faComment,
    faCommentDots,
    faCheck,
    faCalendarTimes,
    faCalendarCheck,
    faStickyNote,
    faInfoCircle,
    faAsterisk,
    faEye,
    faPhone,
    faMobile,
    faNewspaper,
    faSearch,
    faBriefcase,
    faCalendar,
    faPaperclip,
    faMoneyBillAlt,
    faMoneyBillWave,
    faCreditCard,
    faPuzzlePiece,
    faSquare,
    faArrowsH,
    faBullhorn,
    faQuestionCircle,
    faFlask,
    faRocket,
    faArrows,
    faArrowsV,
    faTrophy,
    faTrophyAlt,
    faThumbsUp,
    faThumbsDown,
    faThermometerFull,
    faThermometerHalf,
    faThermometerEmpty,
    faChartPie,
    faTable,
    faLink,
    faSitemap,
    faSlidersH,
    faDownload,
    faUpload,
    faBeer,
    faTint,
    faCar,
    faGem,
    faBinoculars,
    faAnchor,
    faUtensils,
    faMusic,
    faMoon,
    faSignature,
    faStar,
    faStarHalfAlt,
    faBicycle,
    faWheelchair,
    faTree,
    faMagnet,
    faHeart,
    faBomb,
    faCocktail,
    faBullseye,
    faBowlingPins,
    faPaperPlane,
    faTag,
    faExclamationCircle,
    faExchange,
    faCashRegister,
    faCloud,
    faBug,
    faComments,
    faLanguage,
};
